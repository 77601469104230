// por conta do styled-components não passar o themeprovider para outro package
// o uso de um super set para poder receber o themeprovider reexportando os componentes do commons

import {
	BasicButton as BasicBtn,
	Button as B,
	ButtonLink as BL,
	ButtonMenu as BM,
	ButtonReturn as BR,
} from '@cuidardigital/commons/components/Button'
import { Span as S } from '@cuidardigital/commons/components/Typography'
import styled from 'styled-components'
import {
	Button as Btn,
	ButtonCheckable as BtnCheckable,
	FileButton as BtnFile,
} from '@cuidardigital/commons/components/Button/button'

export const ButtonCheckable = styled(BtnCheckable)``
export const ButtonStyled = styled(Btn)``
export const FileButton = styled(BtnFile)``

interface IButton {
	active?: string | boolean
	bg?: string
	width?: string
}

export const BasicButton = styled(BasicBtn)`
	${({ theme, bg }) => `background: ${theme.colors[bg]};`};
`
export const PrimaryButton = styled(B)``
export const Button = styled(B)<IButton>`
	background: transparent
		linear-gradient(
			0deg,
			${props => (props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary)}
				0%,
			${props =>
					props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary_hover}
				100%
		)
		0% 0% no-repeat padding-box;

	&:hover {
		background: ${props =>
			props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary_hover};
	}

	font: 16px/23px CentraleSansRegular;
	@media (max-width: 1340px) {
		font: 14px/23px CentraleSansRegular;
	}
	@media (max-width: 768px) {
		font: 12px/23px CentraleSansRegular;
	}
`
export const ButtonLink = styled(BL)`
	${({ theme, bg }) => bg && `background: ${theme.colors[bg]};`}
`
export const ButtonReturn = styled(BR)`
	border: 1px solid ${props => props.theme.colors.primary_hover};
	margin: 32px 0 0;
	width: 48%;
	color: ${props => props.theme.colors.primary_hover};
	background: ${props => props.theme.colors.transparent};

	&:hover {
		background: ${props => props.theme.colors.transparent_hover};
	}

	font: 16px/23px CentraleSansRegular;
	@media (max-width: 1340px) {
		font: 14px/23px CentraleSansRegular;
	}
	@media (max-width: 768px) {
		font: 12px/23px CentraleSansRegular;
	}
`
export const ButtonMenu = styled(BM)``

export const SpanHighlight = styled(S)`
	color: ${props => props.theme.colors.font_highlight};
`
export const Span = styled(S)`
	color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.font_dark)};
`
export const SpanDisabled = styled(S)`
	color: ${props => props.theme.colors.font_disabled};
`

export const SubmitButtonRed = styled(Button)`
	margin: 34px auto 0;
	// margin-top: 40px;
	border-radius: 2px;
	${props => (props.half ? 'width: 48%; margin: 32px 0 0 4%;' : '')};
	${props =>
		props.disabled
			? `
	border: 1px solid ${props.theme.colors.button_disabled_border};
	background: ${props.theme.colors.button_disabled};
	`
			: `
	border: 1px solid ${props.theme.colors.red_alert};
	background: transparent linear-gradient(180deg, ${props.theme.colors.red_alert_hover}
     0%, ${props.theme.colors.red_alert} 100%) 0% 0% no-repeat padding-box;
	`};

	&:hover {
		background: ${props =>
			props.disabled ? props.theme.colors.secundary : props.theme.colors.red_alert_hover};
	}
`

export const InputError = styled(S)`
	text-align: left;
	// font: 12px/18px CentraleSansRegular;
	letter-spacing: 0px;
	color: ${props => props.theme.colors.red_alert};
`

export const InputLabel = styled(S)`
	display: block;
	text-align: left;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
`

export const InputElement = styled.input`
	padding: ${({ p }) => p || '0px'};
	background: ${({ bg, theme }) => theme.colors[bg || 'transparent']};
	${({ width }) => width};
	${({ minWidth }) => minWidth};
	${({ withBorder, theme, error }) =>
		withBorder && `border-bottom: solid 1px ${error ? theme.colors.red_alert : '#CCCCCC'}`};
	${({ withAllBorder, theme, error }) =>
		withAllBorder && `border: solid 1px ${error ? theme.colors.red_alert : '#CCCCCC'}`};

	text-align: left;
	font: 14px/32px CentraleSansRegular;
	letter-spacing: 0;
	color: ${({ theme, color, disabled }) =>
		disabled ? theme.colors.font_disabled : theme.colors[color || 'font_dark']};
	&::placeholder {
		color: ${props => props.theme.colors.font_disabled};
	}
	outline: none;

	@media (max-width: 1340px) {
		font: 12px/32px CentraleSansRegular;
	}
	@media (max-width: 768px) {
		font: 10px/32px CentraleSansRegular;
	}
	@media (max-width: 480px) {
		min-width: auto;
	}
`
