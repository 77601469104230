import React, { useRef } from 'react'
import styled from 'styled-components'

const FONT_SIZES = {
	1024: {
		'32px/40px': '28px/32px',
		'24px/32px': '20px/28px',
		// '24px/32px': '24px/32px',
		'24px/24px': '20px/24px',
		'22px/22px': '22px/22px',
		'20px/20px': '16px/20px',
		'16px/20px': '12px/20px',
		'16px/24px': '12px/24px',
		// '14px/24px': '10px/14px',
		'14px/24px': '10px/14px',
		'14px/20px': '10px/20px',
		'14px/16px': '10px/16px',
		'12px/20px': '8px/20px',
		'12px/14px': '8px/14px'
	},
	768: {
		'32px/40px': '26px/32px',
		'24px/32px': '18px/28px',
		'24px/24px': '18px/24px',
		'22px/22px': '22px/22px',
		'20px/20px': '14px/20px',
		'16px/20px': '10px/20px',
		'16px/24px': '10px/14px',
		// '14px/24px': '8px/24px',
		'14px/16px': '12px/16px',
		'14px/24px': '8px/12px',
		'14px/20px': '8px/20px',
		'12px/20px': '7px/20px',
		'12px/14px': '7px/14px'
	}
}

interface ISpan {
	fontSize?:
		| '32px/40px'
		| '24px/32px'
		| '24px/24px'
		| '22px/22px'
		| '20px/20px'
		| '16px/20px'
		| '16px/24px'
		| '14px/16px'
		| '14px/22px'
		| '14px/24px'
		| '14px/20px'
		| '12px/20px'
		| '12px/14px'
	fontsm?: string
	color?: string
	mb?: string
	bold?: boolean
	width?: string
	children?: any
	lineHeight?: string
	wrap?: boolean
	padding?: string
	id?: string
	display?: string
	margin?: string
	sm?: string
	notMobile?: string
	textAlign?: string
	dangerouslySetInnerHTML?: { __html: string }
	className?: string
}

const SpanCss = styled.span`
	font: ${({ bold, fontSize }: ISpan) =>
		`${bold ? 'Bold' : ''} ${fontSize || '16px/20px'} CentraleSans${bold ? '-Bold' : 'Regular'}`};
	letter-spacing: 0;
	color: ${({ color }: ISpan) => color || '#333333'};
	${({ margin }: ISpan) => margin && `margin: ${margin}`};
	${({ mb }: ISpan) => mb && `margin-bottom: ${mb}`};
	${({ width }: ISpan) => width && `width: ${width};`}
	line-height: ${({ lineHeight }) => lineHeight || 1.2};
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	${({ wrap }) => wrap && 'white-space: normal;'}
	${({ padding: p }) => p && `padding: ${p};`}
	${({ display }) => display && `display: ${display};`}
	${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
	${({ minWidth }) => minWidth && `min-width: ${minWidth};`}

	strong, b {
		font-family: CentraleSans-Bold;
	}


	@media (max-width: 1340px) {
		font: ${({ bold, fontSize, fontsm }: ISpan) =>
			`${bold ? 'Bold' : ''} ${FONT_SIZES[1024][fontSize] || '12px/12px'} CentraleSans${
				bold ? '-Bold' : 'Regular'
			}`};
	}
	@media (max-width: 768px) {
		font: ${({ bold, fontSize, fontsm }: ISpan) =>
			`${bold ? 'Bold' : ''} ${FONT_SIZES[768][fontSize] || '10px/12px'} CentraleSans${
				bold ? '-Bold' : 'Regular'
			}`};
	}
	@media (max-width: 480px) {
		${props => (props.sm === '0' ? 'display: none;' : '')}
		${props => props.notMobile && 'display: none;'}
	}

`

export const Span = ({ children, ...props }: ISpan) => {
	const ref = useRef(null)
	return (
		<SpanCss
			ref={ref}
			className='span'
			title={children || ref?.current?.innerText}
			id={ref?.current?.parentNode.getAttribute('id')}
			{...props}
		>
			{children}
		</SpanCss>
	)
}
