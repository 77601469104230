import React from 'react'
import styled, { css } from 'styled-components'

interface IButton extends React.Component<HTMLButtonElement> {
	width?: string
	height?: string
	disabled?: boolean
	bg?: string
	color?: string
	p?: string
	active?: boolean
	onClick?: any
	radius?: string
	display?: string
	alignItems?: string
	border?: string
	borderBottom?: string
	hoverColor?: string
	discreet?: boolean
	hover?: string
	padding?: string
}

const ButtonCss = css`
	outline: none;
	width: ${({ width }: IButton) => width || '100%'};
	${({ display }: IButton) => `display: ${display}`};
	height: ${({ height }) => height || '45px'};
	// height: min-content;
	border-radius: ${props => props.radius || '4px'};
	opacity: 1;
	font: Bold 16px/23px CentraleSans-Bold;
	letter-spacing: 0;
	padding: ${({ p }) => p || '8px'};
	cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
	 text-align: center;

	&:hover {
		box-shadow: 2px 2px -10px #00000026;
	}

	@media (max-width: 1340px) {
		font: 14px/23px CentraleSans-Bold;
	}
	@media (max-width: 1024px) {
		font: 12px/16px CentraleSans-Bold;
	}
	@media (max-width: 768px) {
		font: 12px/23px CentraleSans-Bold;
	}

	@media (max-width: 480px) {
		padding: ${({ p }) => p || '5px'};
		height: ${({ height }) => height || '35px'};
	}
`
export const BasicButton = styled.button<IButton>`
	${ButtonCss}
`

export const DeafultButton = styled.button<IButton>`
	${ButtonCss}
	padding: ${({ p }) => p || '5px 1px'};
	font-size: 16px;
	border: 1px solid
		${({ color, disabled, theme }) =>
			disabled
				? theme.colors.button_disabled_border
				: color
				? theme.colors[`${color}_hover`]
				: theme.colors.primary_hover};
	color: ${({ color, disabled, theme }) =>
		color || (disabled ? theme.colors.font_disabled : theme.colors[color] || theme.colors.font)};
	background: ${props =>
		props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary};
	&:hover {
		background: ${props =>
			props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary_hover};
	}
`

export const ButtonLink = styled.button<IButton>`
	${ButtonCss}
	border: none;
	color: ${props => props.theme.colors.font_secundary};
	background: ${props => props.theme.colors.transparent};
	font: 14px/24px CentraleSansRegular;
	${({ alignItems }) => alignItems && 'align-items: center;'}
	${({ theme, borderBottom }) =>
		borderBottom && `border-bottom: 1px solid ${theme.colors[borderBottom]};`}
	${({ theme, border }) => border && `border: 1px solid ${theme.colors[border]};`}
	&:hover {
		background: ${props =>
			props.border || props.hoverColor
				? props.theme.colors[props.hoverColor || 'menu_button_bg_hover']
				: props.theme.colors.transparent_hover};
	}
`
export const ButtonMenu = styled.button<IButton>`
	${ButtonCss}
	color: ${props => props.theme.colors.font};
	border: 2px solid #f7f7f7;
	background: ${props => props.theme.colors.transparent};
	&:hover {
		background: ${props => props.theme.colors.transparent_hover};
		box-shadow: 2px 2px -10px #00000026;
	}

	@media (max-width: 768px) {
		font: 12px/24px CentraleSansRegular;
	}
`

export const ButtonReturn = styled.button<IButton>`
	${ButtonCss}
	border: 1px solid ${props => props.theme.colors.primary_hover};
	margin: 32px 0 0;
	width: 48%;
	color: ${props => props.theme.colors.primary_hover};
	background: ${props => props.theme.colors.transparent};

	&:hover {
		background: ${props => props.theme.colors.transparent_hover};
	}
`

// STYLED GUIDE
interface IProps extends IButton {
	brand: string
	type:
		| 'primary'
		| 'secundary'
		| 'alert'
		| 'primary-void'
		| 'secundary-void'
		| 'alert-void'
		| 'blue'
	link?: boolean
	disabled?: boolean
	children?: any
	discreet?: boolean
	isLink?: boolean
	justifyContent?: 'space-between' | 'space-around' | 'center' | 'flex-end' | 'flex-start'
	alingItems?: 'center' | 'flex-end' | 'flex-start'
	flexDirection?: 'column' | 'row'
	fullWidth?: boolean
	xsMargin?: string
	xsPadding?: string
	margin?: string
	halfWidth?: boolean
	bg?: string
	onClick?: any
	zIndex?: string
	position?: string
}
const Css = css<IProps>`
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	cursor: pointer;
	height: ${({ discreet: discret }) => (discret ? '33px' : '46px')};
	align-items: ${({ alingItems }) => alingItems || 'center'};
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
	${({ padding }) => padding && `padding: ${padding}};`}
	${({ margin }) => margin && `margin: ${margin}};`}
	${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
	${({ fullWidth }) => fullWidth && 'width: 100%;'}
	${({ halfWidth }) => halfWidth && 'width: 50%;'}
	border: none;
	${({ theme, borderBottom }) =>
		borderBottom && `border-bottom: 1px solid ${theme.colors[borderBottom]};`}
	${({ radius }) => radius && `border-radius: ${radius};`}
	${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
	${({ position }) => position && `position: ${position};`}

	&:hover {
		box-shadow: 2px 2px -10px #00000026;
	}

	@media(max-width: 480px){
		${({ xsPadding }) => xsPadding && `xsPadding: ${xsPadding}};`}
	${({ xsMargin }) => xsMargin && `xsMargin: ${xsMargin}};`}
	${({ halfWidth }) => halfWidth && 'width: 100%;'}

	}
`

const Button = styled.button<IProps>`
	${Css}

	${({ theme, type = 'primary', disabled, isLink, bg: forceBg }) => {
		const styledProps = { bg: '', hover: '', border: '', color: '', hoverColor: '' }
		switch (type) {
			case 'primary':
			case 'secundary':
			case 'alert':
			case 'blue':
				styledProps.bg = type
				styledProps.color = 'white'
				styledProps.hover = `${type}_hover`
				break
			default:
				const typeColor = type.replace('-void', '')
				styledProps.bg = 'transparent'
				styledProps.border = typeColor
				styledProps.hover = `${typeColor}_hover`
				styledProps.color = typeColor
				styledProps.hoverColor = 'white'
				break
		}
		if (isLink) {
			styledProps.hover = 'transparent'
			styledProps.hoverColor = `${type.replace('-void', '')}_hover`
			switch (type) {
				case 'primary':
				case 'secundary':
				case 'alert':
				case 'blue':
					styledProps.bg = 'transparent'
					styledProps.color = type
					break
				default:
					styledProps.border = 'none'
					break
			}
		}

		if (disabled)
			switch (type) {
				case 'primary':
				case 'secundary':
				case 'alert':
				case 'blue':
					styledProps.bg = 'button_disabled'
					styledProps.color = 'fontButtonDisabled'
					styledProps.hover = 'button_disabled'
					styledProps.hoverColor = 'fontButtonDisabled'
					break
				default:
					styledProps.border = 'fontButtonDisabled'
					styledProps.hover = 'none'
					styledProps.hoverColor = 'fontButtonDisabled'

					break
			}

		let result = ''
		const { bg, hover, border, color, hoverColor } = styledProps
		if (bg || forceBg) result = `${result} background: ${theme.colors[forceBg || bg]};`
		if (border) result = `${result} border: 1px solid ${theme.colors[border]};`
		if (color)
			result = `${result} color: ${theme.colors[color]}; span {
		color: ${theme.colors[color]};
	}`
		if (hover || hoverColor)
			result = `${result}
		&:hover {
			background: ${theme.colors[hover]};
			color: ${theme.colors[hoverColor]};
			span {
				color: ${theme.colors[hoverColor]};
			}
		}
	`
		return result
	}}
`

interface ICheckable extends IButton {
	bg?: string
	bgIcon?: string
	checked: boolean
	filter?: boolean
}
const CssCheckable = css`
	padding: 0 10px;
	${Css}
	border-radius: 21.5px;
	height: 43px;

	&:hover {
		box-shadow: 2px 2px -10px #00000026;
	}
`
const ButtonCheckable = styled.button<ICheckable>`
	${CssCheckable}

	// svg {
	// 	width: auto;
	// 	height: auto;
	// 	border-radius: 50%;
	// }

	${({ theme, filter, checked }) => {
		const styledProps: ICheckable = {}
		if (filter) styledProps.bg = 'gray80'
		else styledProps.bg = 'ice'

		if (checked) {
			styledProps.bg = 'blue'
			styledProps.bgIcon = 'gray10'
		}

		const { bg, bgIcon } = styledProps
		let result = ''
		if (bg) result = `${result} background: ${theme.colors[bg]};`
		if (bgIcon) result = `${result}  svg { background: ${theme.colors[bgIcon]};}`
		return result
	}}
`

interface IFileButton {
	for: string
	active: boolean
}
const FileButton = styled.label<IFileButton>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;

	background: ${({ active, theme }) => (active ? theme.colors.blueFullish : theme.colors.blue)};
	border-radius: 4px;
	cursor: pointer;
`

export { Button, ButtonCheckable, FileButton }
